<template>
  <div id="home">
    <!-- <h1>home</h1><br> -->
    <!-- <div class="d-none d-lg-block">
    <iframe frameborder="0" src="https://itch.io/embed-upload/5027530?color=1C2122" allowfullscreen="" width="1000"
        height="650"><a href="https://omnisepher.itch.io/pizza-time">Play Pizza Time on itch.io (If sound doesn't come,
          it might be browser related)</a></iframe>

    </div> -->
    <i class="fas fa-3x fa-wrench"></i>
    <h1>tutorials</h1>
    <b-container>
      <div class="vidContainer">
        <iframe id="frontVid" src="https://www.youtube.com/embed/l6twNaup1tI" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <b-row no-gutters>
        <b-col class="m-0" cols="4">
          <router-link to='/code'><img class="thumbnail" src="./..\assets\img\thumbnails\The Village Thumbnail.jpg"
              alt=""></router-link>
        </b-col>
        <b-col class="m-0" cols="4">
          <router-link to='/code'><img class="thumbnail" src="./..\assets\img\thumbnails\PIZZA TIME WWise Thumbnail.jpg"
              alt=""></router-link>
        </b-col>
        <b-col class="m-0" cols="4">
          <router-link to='/code'><img class="thumbnail" src="./..\assets\img\thumbnails\PIZZA TIME FMOD Thumbnail.jpg"
              alt=""></router-link>
        </b-col>
        <b-col class="m-0" cols="4">
          <router-link to='/code#unrealTutos'><img class="thumbnail"
              src="./..\assets\img\thumbnails\WWise UE4 Thumbnail.jpg" alt=""></router-link>
        </b-col>
        <b-col class="m-0" cols="4">
          <router-link to='/code#unityTutos'>
            <img class="thumbnail" src="./..\assets\img\thumbnails\WWise Unity Thumbnail.jpg" alt=""></router-link>
        </b-col>
      </b-row>
    </b-container>

    <!-- <i style="margin-top:100px;" class="fas fa-3x fa-gamepad"></i>
    <h1>game jams</h1>
    <b-container>
      <b-row no-gutters>
        <b-col class="imageFrame" cols="4" v-for="index in 3" :key="index">
          <router-link to='/code#gameJams'><img class="frontImage" :src="games[index-1].images[0]"></router-link>
        </b-col>
      </b-row>
    </b-container> -->
    <div style="height:18vh;"></div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import axios from 'axios';
  import gameThumbnail from "../components/gameThumbnail";
  import game from "../db/game.json"

  export default {
    name: "Home",
    components: {
      gameThumbnail,
    },
    data() {
      return {
        games: game
      };
    },
    created() {

    }
  };
</script>

<style scoped lang="scss">
  hr {
    width: 50%;
    border-top: 1px solid aliceblue;
  }

  .perk {
    margin-bottom: 40px;
  }

  .smallLogos {
    max-height: 70px;
    height: 10vw;
    margin: 2px 5px;
    padding: 5px;
    border-left: 1px dashed $secondaryColor;
    border-bottom: 1px dashed $secondaryColor;
    border-radius: 10px;
    box-shadow: 1px -1px $primaryColor;
  }

  .thumbnail {
    width: 100%;
    border: $primaryColor 2px solid;
    margin: 10px 0;
  }

  .thumbnail:hover,
  .frontImage:hover {
    opacity: 0.8;
  }

  #frontVid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .vidContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    border: 2px solid $primaryColor;
  }

  .frontImage {
    width: 100%;
    height: calc(15vw);
    object-fit: cover;
    margin: 10px 0;
    border: 2px solid $defWhite;
  }
</style>