import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta';
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Music from "../views/Music.vue";
import Code from "../views/Code.vue";
import Contact from "../views/Contact.vue";
import Spotify from "../views/Spotify.vue";
import Youtube from '../views/Youtube.vue';

Vue.use(VueMeta)
Vue.use(VueRouter)

  const routes = [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/about",
      name: "About",
      component: About,
    },
    {
      path: "/music",
      name: "Music",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: Music,
    },
    {
      path: "/code",
      name: "Code",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: Code,
    },
    {
      path: "/contact",
      name: "Contact",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: Contact,
    },
    // {
    //   path: "/spotify",
    //   beforeEnter() {
    //     location.href =
    //       "https://open.spotify.com/artist/4vVwMqAlihdVSPZH6c7bxf?si=U1Ca-HHdQYmNFaJW7CgayA";
    //   },
    // },
    // {
    //   path: "/spotify",
    //   name: "Spotify",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: Spotify,
    // },
    {
      path: "/youtube",
      name: "Youtube",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: Youtube,
    },
    {
      path: '*',
      name: 'not-found',
      component: Home,
    }
    // {
    //   path: "/youtube",
    //   beforeEnter() {
    //     location.href =
    //       "https://www.youtube.com/channel/UCABy74N6vsQGqhghUaLlKyA";
    //   },
    // },
  ];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
