<template>
  <div class="code">
    <h1>code</h1><br>
    <b-container>
      <b-row>
        <b-col cols="6" style="text-align:right;">
          <a href="https://github.com/omnisepher"><i class="fa-3x fab fa-github"></i>
            <h1>github</h1>
          </a>
        </b-col>
        <b-col cols="6" style="text-align:left;">
          <a href="files/cv_muratozcan.pdf"><i class="fa-3x fas fa-file"></i>
            <h1>resume</h1>
          </a>
        </b-col>
      </b-row>
    </b-container>

    <hr>
    <i class="fab fa-3x fa-medium"></i>
    <h1>articles</h1>
    <b-container>
      <h5 v-for="article in this.articles" :key="article"><a :href="article.link">{{article.title}}</a></h5>
    </b-container>
    <hr>
    <i class="fab fa-3x fa-youtube"></i>
    <h1>videos</h1>
    <b-container id="unityTutos">
      <h3 class="mt-4">Unity/WWise/C#</h3>
      <b-row>
        <b-col cols="12">
          <h4 class="mt-4">WWise Time</h4>
          <div class="ytframe">
            <iframe width="560" height="315"
              src="https://www.youtube.com/embed/videoseries?list=PLU7dm0W1bex0zjxVjlXhE8iew0F0eK7aJ"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>

          </div>
        </b-col>
        <b-col cols="12">
          <h4 class="mt-4">FMOD Time</h4>
          <div class="ytframe">

            <iframe width="560" height="315"
              src="https://www.youtube.com/embed/videoseries?list=PLU7dm0W1bex377k97A0aUVYcXSfuyENYO"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </b-col>
        <b-col cols="12">
          <h4 class="mt-4">Be WWise with Unity</h4>
          <div class="ytframe">

            <iframe width="560" height="315"
              src="https://www.youtube.com/embed/videoseries?list=PLU7dm0W1bex27eB1VfOjlZuQ_KAYZ4qPo"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </b-col>
      </b-row>

    </b-container>
    <hr>
    <b-container id="unrealTutos">
      <h3 style="margin-top:70px;">UE4/WWise/C++</h3>
      <b-row>
        <b-col cols="12">
          <h4 class="mt-4">Learning WWise with Unreal Engine 5</h4>
          <div class="ytframe">

            <iframe width="560" height="315"
              src="https://www.youtube.com/embed/videoseries?list=PLU7dm0W1bex3O4UJgiPQi_eLq3Er9ALOG"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </b-col>
        <b-col cols="12">
          <h4 class="mt-4">Unrealizing WWise</h4>
          <div class="ytframe">

            <iframe width="560" height="315"
              src="https://www.youtube.com/embed/videoseries?list=PLU7dm0W1bex2b6IJua6Wz9PDINPHggAGP"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container id="gameJams">
      <h3 style="margin-top:70px;">GameJam</h3>
      <b-row>
        <b-col class="mt-2" v-for="video in this.gamejamVids" :key="video" cols="6" lg="4">
          <a :href="video.link"><img class="videoJam" :src="video.thumbnail" alt=""></a>
        </b-col>
      </b-row>
    </b-container>

    <hr>
    <i class="fa-3x fas fa-keyboard"></i>
    <h1>overview</h1>
    <b-container>
      <b-row no-gutters>
        <b-col class="gameItem" cols="6" v-for="game in games" :key="game.name">
          <gameItem v-bind:name="game.name" v-bind:status="game.status" v-bind:purpose="game.purpose"
            v-bind:role="game.role" v-bind:desc="game.desc" v-bind:links="game.links" v-bind:images="game.images"
            v-bind:platforms="game.platforms" v-bind:credits="game.credits" v-bind:engine="game.engine"
            v-bind:free="game.free" />
        </b-col>
      </b-row>
    </b-container>


    <div style="height:18vh;"></div>
  </div>
</template>

<script>
  import gameItem from '../components/gameItem';
  import game from "../db/game.json"
  import axios from 'axios';
  import youtube from "../db/youtube.json"

  const YTKEY = "AIzaSyDqT2MtOuoJ4qqwzf85ZmNXHJtD99HWBDE"

  export default {
    components: {
      gameItem
    },
    data() {
      return {
        articles: [],
        videos: [],
        BWWUnity: [],
        UnreWWise: [],
        WWiseTime: [],
        FMODTime: [],
        SandemoniumVids: [],
        gamejamVids: [],
        games: game
      }
    },
    created() {
      axios.get('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40septagra').then(
        response => {
          // console.log(response.data.items);
          this.articles = response.data.items;
        })
    }
  }
</script>

<style scoped lang="scss">
  .ytframe {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
  }

  .ytframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  hr {
    width: 50%;
    border-top: 1px solid aliceblue;
  }

  .gameItem {
    margin: 2px 0;
  }

  .article {
    /* max-width: 20rem; */
    background-color: black !important;
    border-color: $defWhite;
    border-style: solid;
    border-width: 2px;
  }

  .videoUN {
    border-color: $primaryColor;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    width: 100%;
  }

  .videoUE {
    border-color: $secondaryColor;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    width: 100%;
  }

  .videoJam {
    border-color: $defWhite;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    width: 100%;
  }
</style>

<style>
  .gameItem {
    margin: 10px 0;
  }
</style>