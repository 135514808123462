<template>
  <div class="spotify">
  </div>
</template>

<script>
export default {
  beforeCreate: function(){
    location.href =
          "https://open.spotify.com/artist/4vVwMqAlihdVSPZH6c7bxf?si=U1Ca-HHdQYmNFaJW7CgayA";
  }
}
</script>
