<template>
  <div class="youtube">
  </div>
</template>

<script>
export default {
  beforeCreate: function(){
    location.href =
          "https://www.youtube.com/c/OmnisepherGameSound";
  }
}
</script>
