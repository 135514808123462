<template>

  <div id="app" style="min-height: 100%;margin:0;">
    <div id="nav">
      <div class="topBackground">
        <b-container fluid style="margin-bottom:-10px;">
        <b-row>
          <b-col cols="2">
            <b-nav align="left">
              <b-nav-item>
                <router-link to='/'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 90.91" width="150" height="60">
                    <defs></defs>
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path class="logoBase"
                          d="M198.48,28.09l-8.94,1.58a91.62,91.62,0,0,1,0,31.57l8.94,1.58a100,100,0,0,0,0-34.73Z" />
                        <path class="logoBase"
                          d="M174.15,10.88l-8.23,3.83a72.81,72.81,0,0,1,0,61.49L174.15,80a81.76,81.76,0,0,0,0-69.15Z" />
                        <path class="logoBase"
                          d="M145,.46l-6.43,6.43a54.53,54.53,0,0,1,0,77.13L145,90.45a63.63,63.63,0,0,0,0-90Z" />
                        <path class="logoBase"
                          d="M100,0a45.46,45.46,0,1,0,45.45,45.45A45.45,45.45,0,0,0,100,0Zm0,81.82a36.37,36.37,0,1,1,36.36-36.37A36.36,36.36,0,0,1,100,81.82Z" />
                        <path id="O_Letter" data-name="O Letter" class="logoBase"
                          d="M100,18.18a27.28,27.28,0,1,0,27.27,27.27A27.27,27.27,0,0,0,100,18.18Zm0,45.46a18.19,18.19,0,1,1,18.18-18.19A18.18,18.18,0,0,1,100,63.64Z" />
                        <circle id="O_Inside" data-name="O Inside" class="logoBase" cx="100" cy="45.45" r="9.09" />
                        <path class="logoBase"
                          d="M9.09,45.45a91.15,91.15,0,0,1,1.37-15.78L1.52,28.09a100,100,0,0,0,0,34.73l8.94-1.58A91.27,91.27,0,0,1,9.09,45.45Z" />
                        <path class="logoBase"
                          d="M27.27,45.45a72.35,72.35,0,0,1,6.81-30.74l-8.23-3.83a81.76,81.76,0,0,0,0,69.15l8.23-3.83A72.37,72.37,0,0,1,27.27,45.45Z" />
                        <path class="logoBase"
                          d="M45.45,45.45a54.36,54.36,0,0,1,16-38.56L55,.46a63.63,63.63,0,0,0,0,90L61.43,84A54.37,54.37,0,0,1,45.45,45.45Z" />
                      </g>
                    </g>
                  </svg>
                </router-link>
              </b-nav-item>
            </b-nav>
          </b-col>
          <b-col cols="10">
            <b-nav style="padding-top:20px;" align="right">

              <b-nav-item>
                <router-link to='/'>
                  <h3 class="navItem">home</h3>
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link to='/about'>
                  <h3 class="navItem">about</h3>
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link to='/code'>
                  <h3 class="navItem">code</h3>
                </router-link>
              </b-nav-item>
            </b-nav>
          </b-col>
        </b-row>
                
        </b-container>
        <b-container fluid>

      <b-row>
          <b-col>

          </b-col>
          <b-col cols="9">
            <div id="iconTab">
              <a href="https://www.linkedin.com/in/omnisepher/"><i
                  class="fa-2x fab fa-linkedin fa-linkedin icon"></i></a>
              <a href="https://www.youtube.com/channel/UC4nP-QV2UFWkan83ovlbiqw"><i
                  class="fa-2x fab fa-youtube icon"></i></a>
              <a href="https://github.com/omnisepher"><i class="fa-2x fab fa-github icon"></i></a>
              <a href="files/cv_muratozcan.pdf"><i class="fa-2x fas fa-file icon"></i></a>
              <a href="mailto:omnisepher@gmail.com"><i class="fa-2x far fa-envelope icon"></i></a>

            </div>
          </b-col>
        </b-row>
      </b-container>
      </div>
      


      <transition name="view">
        <router-view>

        </router-view>
      </transition>

    </div>
  </div>

</template>

<script>
  import axios from 'axios';
  import Vue from 'vue'
  import {
    BootstrapVue,
    IconsPlugin
  } from 'bootstrap-vue'
  Vue.use(BootstrapVue)
  Vue.use(IconsPlugin)

  export default {
    data() {
      return {
        metaInfo: {
          title: "Omnisepher"
        }
      }
    },
    methods: {},
  }
</script>

<style lang="scss">
  #iconTab {
    font-size: calc(0.5vw + 0.5rem);
    // margin-top: 3px;
    margin-bottom: 0;
    text-align: right;
  }

  .icon {
    margin: 0 10px;
  }

  .navItem {
    font-size: calc(0.5em + 1.1vw);
  }

  .logoBase {
    fill: $primaryColor;
  }

  .logoText {
    fill: $primaryColor;
  }

  .logoWaveOut {
    fill: $primaryColor;
    opacity: 0.2;
  }
</style>

<style lang="scss">
  .topBackground {
    background-color: $backgroundColor;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  #menu {
    // margin-top: -1vw;
  }


  .class {
    margin: 0;
    padding: 0;
  }


  #app {
    font-family: cvFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $defWhite;
    // background-color: $backgroundColor;

  }

  #nav a {
    font-weight: bold;
    color: $defWhite;
  }

  #nav a:hover {
    font-weight: bold;
    color: $defWhite;
    text-decoration-line: none;
  }

  #nav a.router-link-exact-active {
    color: $secondaryColor;
  }

  .view-enter-active,
  .view-leave-active {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease;
  }

  .view-enter-active {
    transition-delay: 0.5s;
  }

  .view-enter,
  .view-leave-to {
    opacity: 0;
    transform: translateY(50px);
  }

  .view-enter-to,
  .view-leave {
    opacity: 1;
    transform: translateY(0px);
  }
</style>

<style lang="scss" scoped>
  hr {
    border-top: 1px solid $defWhite;
  }
</style>