<template>
    <div class="gameItem"  v-on:click="openDetails">
        <h1 class="title">{{name}}</h1>
        
        <div v-if="this.visDetails">
            <hr>
            <b-row>
                <b-col col cols="12" lg="4">
                    <a v-if="images[0]" :href="links.itch"><img class="frontImage" :src="images[0]" alt=""
                            height="auto"></a>
                    <br>
                    <div v-if="images[0]">
                        <i v-if="platforms.win" class="otherLogo fa-x fab fa-windows"></i>
                        <i v-if="platforms.android" class="otherLogo fa-x fab fa-android"></i>
                        <i v-if="platforms.mac" class="otherLogo fa-x fab fa-apple"></i>
                    </div>

                </b-col>
                <b-col col cols="12" lg="8">
                    <h5>Status: <span :style="{color: statusCol}">{{status}}</span></h5>
                    <h4 style="margin-bottom:30px;">{{purpose}}</h4>
                    <h5>{{desc}}</h5>
                    <a v-if="links.github" :href="links.github"><i class="platformLogo fa-2x fab fa-github"></i></a>
                    <a v-if="links.itch" :href="links.itch"><i class="platformLogo fa-2x fab fa-itch-io"></i></a>

                    <hr>
                    <h4>Credits <br></h4>
                    <div class="credits">
                        <span style="font-size:0.7em;" v-if="free">(Free assets below)</span><br>
                        <i class="otherLogo fa-2x fab fa-unity"></i>
                        <a v-if="free" :href="free"><i v-if="free" class="otherLogo fa-2x fas fa-cube"></i></a>

                        <h5><b>Me</b>: {{role}}</h5>
                        <h5 v-if="credits.name"><a class="creditName" style="text-decoration:none;"
                                :href="credits.link"><i
                                    class="platformLogo fa-2x fab fa-itch-io"></i>{{credits.name}}</a>: {{credits.role}}
                        </h5>
                    </div>

                </b-col>
            </b-row>
        </div>

    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "gameItem",
        props: ['name', "status", "purpose", 'role', 'desc', 'links', 'images', 'credits', 'platforms', 'engine',
            'free'
        ],
        data() {
            return {
                statusCol: "",
                engineLogo: "",
                visDetails:false
            }
        },
        created() {
            this.fixPrototype();
        },
        methods: {
            fixPrototype() {
                if (this.status == "Prototype") {
                    this.statusCol = "red"
                } else if (this.status == "On Progress") {
                    this.statusCol = "yellow"
                } else if (this.status == "Done") {
                    this.statusCol = "green";
                } else if (this.status == "Cancelled") {
                    this.statusCol = "darkred";
                }
            },
            openDetails(){
                this.visDetails = !(this.visDetails);
            }
        }


    }
</script>

<style scoped lang="scss">
@keyframes gameItemAnimON {
  from {
      border-radius: 25px;}
  to {
      border-radius: 5px;
      }
}

@keyframes gameItemAnimOFF {
  from {
      border-radius: 5px;}
  to {
      border-radius: 25px;}
}
    .gameItem {
        border: 2px solid $primaryColor;
        border-radius: 25px;
        padding: 10px;
        // font-size: 1em;
        animation-name: gameItemAnimOFF;
        animation-duration: 0.7s;
    }

    .gameItem:hover{
        animation-name: gameItemAnimON;
        animation-duration: 0.7s;
        border-radius: 5px;
        background-color: rgba($primaryColor,0.05);
    }

    .title{
        font-size:calc(0.2em + 2vw);
        margin-bottom:0;
    }

    h4 {
        font-size: calc(1vw + 0.5em);
    }

    h5 {
        font-size: calc(0.8vw + 0.5em);
    }

    .frontImage {
        border: 1px double white;
        border-radius: 10px;
        margin: 10px 10px;
        -webkit-transition: all 0.3s ease;
        ;
        transition: all 0.3s ease;
        width: 80%;
    }

    .frontImage:hover {
        -webkit-filter: brightness(50%);
        filter: brightness(50%);
    }

    .platformLogo {
        padding: 5px 10px;
    }

    .otherLogo {
        padding: 5px 10px;
    }

    .platformLogo:hover {
        filter: blur(0.2px);
        color: $primaryColor;
    }

    .creditName:hover {
        filter: blur(0.1px);
        color: $primaryColor !important;
    }

    hr {
        width: 70%;
        border-top: 1px dashed aliceblue;
    }
</style>