<template>
  <div class="musicItem">

    <a :href="links.spotify" target="_blank"><img class="coverArt" v-bind:src="require('../assets/img/'+image)"
        alt=""></a>
    <h3 style="padding: 5px 0;">{{ name }}</h3>
    <a :href="links.spotify" target="_blank"><i id="spo" class="linkIcon fa-2x fab fa-spotify"></i></a>
    <a :href="links.itunes" target="_blank"><i id="itu" class="linkIcon fa-2x fab fa-itunes-note"></i></a>
    <a :href="links.amazon" target="_blank"><i id="ama" class="linkIcon fa-2x fab fa-amazon"></i></a>
    <a :href="links.youtube" target="_blank"><i id="yt" class="linkIcon fa-2x fab fa-youtube"></i></a>
    <a id="tidal" :href="links.tidal" target="_blank"><span class="icomusic linkIcon icon-tidal"></span></a>
    <a id="deezer" :href="links.deezer" target="_blank"><span class="icomusic linkIcon icon-deezer"></span></a>

    <!-- 
        <p style="margin-top:10px;">Also at <a id="tidal" :href="links.tidal" target="_blank">Tidal <span
              class="icomusic icon-tidal"></span></a>, <a id="deezer" :href="links.deezer" target="_blank">Deezer <span
              class="icomusic icon-deezer"></span></a> and many others</p> -->
    <i>
      <P v-if="painter.name" class="coverArtist">The cover artist is <a :href="painter.link"
          target="_blank">{{ painter.name }}</a></p>
    </i>
  </div>
</template>
<script>
  export default {
    name: 'musicItem',
    props: ['name', 'image', 'links', 'painter']
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a:hover {
    text-decoration-line: none !important;
  }

  .linkIcon {
    padding: 0 calc(5px + 0.4vw);
  }

  .musicItem {
    padding: 40px 0px;
    width: 100%;
    height: auto;
  }

  .coverArt {
    width: calc(150px + 10vw);
    border: 2px #D6C97A solid;
    /* padding: 2px; */
    margin-bottom: 10px;
  }

  .coverArtist{
    padding-top: 10px;
  }
</style>