import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/css/customstyle.css";


Vue.config.productionTip = false
Vue.use(VueMeta);
// Vue.use(LoadScript);

// Vue.loadScript("https://www.retainable.app/assets/retainable/rss-embed/retainable-rss-embed.js");

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
