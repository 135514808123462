<template>
        <a :href="links.itch"><img class="frontImage" :src="images[0]"></a>


</template>

<script>
    import axios from "axios";
    export default {
        name: "gameItem",
        props: ['name', "status", "purpose", 'role', 'desc', 'links', 'images', 'credits', 'platforms', 'engine',
            'free'],
        data() {
            return {
                statusCol: "",
                engineLogo: ""
            }
        },
        created() {
            this.fixPrototype();
        },
        methods: {
            fixPrototype() {
                if (this.status == "Prototype") {
                    this.statusCol = "red"
                } else if (this.status == "On Progress") {
                    this.statusCol = "yellow"
                } else if (this.status == "Done") {
                    this.statusCol = "green";
                } else if (this.status == "Cancelled") {
                    this.statusCol = "darkred";
                }
            }
        }


    }
</script>

<style scoped lang="scss">
.frontImage{
    width: 100%;
    height: 200px;
    object-fit: fill;
    // border-color: 5px solid white;
}

</style>