<template>
  <div class="music">
    <h1>Recording and reuploading...</h1>
    <!--
    <h3 style="margin-top:100px;" class="resp_det">Video Clips</h3>
    <b-container>
      <b-row>
        <b-col class="video-container" lg="6" v-for="vid in videoLinks" :key="vid">
          <iframe class="video" :src="vid.link" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </b-col>
      </b-row>
    </b-container>
    
    <h1>music</h1>
     <b-row>
      <b-col lg="4" md="6" v-for="song in music" :key="song">
        <musicItem v-bind:name="song.name" v-bind:image="song.cover" v-bind:links="song.links" v-bind:painter="song.painter"/>

      </b-col>
    </b-row> -->

    

    <div style="height:18vh;"></div>
  </div>
</template>

<script>
  import musicItem from '../components/musicItem.vue'
  import musicJSON from '../db/music.json';

  export default {
    components: {
      musicItem
    },
    data() {
      return {
        music: musicJSON,
      }
    }
  }
</script>

<style>
  .coverArtist {
    font-size: 0.8em;
  }

  .icomusic {
    font-size: 30px;
  }

  #tid:hover {
    text-decoration-line: none !important;
    color: red !important;
  }

  /* .musicItem {
    padding: 40px;

  } */

  /* .coverArt {
    width: 300px;
    border: 2px #D6C97A solid; 
    padding: 2px;
    margin-bottom: 10px;
  } */

  @media only screen and (max-width: 720px) {
    .musicItem {
      padding: 30px 0;
    }

    .coverArt {
      width: 240px;
      border: 1px aliceblue solid;
      margin-bottom: 10px;
    }
  }
</style>
<style scoped>
  a:hover {
    text-decoration-line: none !important;
  }
</style>